import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://4d679850b9e9f38f40ce58256b0cdc4f@o68744.ingest.us.sentry.io/4507096038178816',

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.2,

		// Optional: Initialize Session Replay:
		integrations: [Sentry.browserTracingIntegration()],
		tracePropagationTargets: [
			'localhost',
			/^https:\/\/whirlwind-chat.*.herokuapp.*/,
			/.*whirlwind.chat.*/
		],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
}

const myErrorHandler: HandleClientError = ({ error }) => {
	console.error('chat_roulette.error', error);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
